import React, { useEffect, useState } from "react";
import { LoadingCenter, Sidebar, Snackbar } from "../../Components";
import { Map } from "../Map";
import { getLand, getPlottingPoint, getUsers } from "../axios/axios";
import "./home.css";
import { useSearchParams } from "react-router-dom";
import logo from "../../Assets/logo.png";

function Home() {
  const [loadingPlotting, setLoadingPlotting] = useState(false);
  const [loadingLand, setLoadingLand] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [plottingPoints, setPlottingPoints] = useState([]);
  const [defaultCenterMap, setDefaultCenterMap] = useState({
    lat: -6.26055485,
    lng: 106.82934195,
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [toggleSidebar, setToggleSidebar] = useState("hide");
  const [showSidebarByMobile, setShowSidebarByMobile] = useState("show");
  const [usersData, setUsersData] = useState({});
  const [selectedPlotting, setSelectedPlotting] = useState({});
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const id = searchParams.get("id");
  const lat = searchParams.get("lat");
  const long = searchParams.get("long");

  useEffect(() => {
    return () => {
      clearTimeout(typingTimeout);
    };
  }, []);

  // useEffect(() => {
  //   if (!code) {
  //     fetchUsers(0);
  //   }
  // }, [code]);

  useEffect(() => {
    if (id) {
      fetchPlottingPoints(id, 0);
    }
  }, [id]);

  useEffect(() => {
    if (code) {
      fetchPlottingPoints(code, 0);
    }
  }, [code]);

  useEffect(() => {
    if (lat && long) {
      let locationPoint = {
        type: "Point",
        coordinates: [Number(long), Number(lat)],
      };
      onSelectedList({ locationPoint: locationPoint });
    }
  }, [lat, long]);

  const onSelectedList = (item) => {
    setSelectedPlotting(item);
    setToggleSidebar("show");
    setShowSidebarByMobile("hide");
    setDefaultCenterMap({
      lat: item?.locationPoint?.coordinates[1],
      lng: item?.locationPoint?.coordinates[0],
    });
    setPlottingPoints([]);
    setTimeout(() => {
      fetchPlottingPoints(item?.id, 0, item?.locationPoint);
    }, 300);
  };

  const onSelectedUser = (item) => {
    if (!item.open) {
      if (item?.plottingData) {
        setUsersData((prevData) => {
          const updatedItems = prevData.items.map((dataItem) => {
            if (dataItem.userId === item.userId) {
              return { ...dataItem, open: true };
            }
            return dataItem;
          });

          return {
            ...prevData,
            items: updatedItems,
          };
        });
      } else {
        fetchLand(0, item?.userId);
      }
    } else {
      setUsersData((prevData) => {
        const updatedItems = prevData.items.map((dataItem) => {
          if (dataItem.userId === item.userId) {
            return { ...dataItem, open: false };
          }
          return dataItem;
        });

        return {
          ...prevData,
          items: updatedItems,
        };
      });
    }
  };

  const onSearchInput = () => {
    fetchLand(0);
  };

  const fetchUsers = async (page) => {
    try {
      const encodedSearchInput = encodeURIComponent("ladang kopi");
      const response = await getUsers(encodedSearchInput, page);
      const itemsWithOpenProperty = response?.data?.items?.map((item) => ({
        ...item,
        open: false,
      }));
      const responseWithOpenProperty = {
        ...response?.data,
        items: itemsWithOpenProperty,
      };
      setUsersData(responseWithOpenProperty);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPlottingPoints = async (id, page, locationPoint) => {
    try {
      setLoadingPlotting(true);
      const response = await getPlottingPoint(id, 0);
      const newData = response.data.items;

      if (response?.data?.totalItems === 0) {
        if (locationPoint) {
          let points = [
            {
              plottingPoint: {
                coordinates: locationPoint?.coordinates,
              },
            },
          ];
          setPlottingPoints(points);
        }
      } else if (response?.data?.totalItems === 1) {
        setDefaultCenterMap({
          lat: response?.data?.items[0]?.plottingPoint?.coordinates[1],
          lng: response?.data?.items[0]?.plottingPoint?.coordinates[0],
        });
        setTimeout(() => {
          setPlottingPoints((prevData) =>
            Array.from(
              new Set([...prevData, ...newData].map(JSON.stringify))
            ).map(JSON.parse)
          );
        }, 500);
      } else {
        setPlottingPoints((prevData) =>
          Array.from(
            new Set([...prevData, ...newData].map(JSON.stringify))
          ).map(JSON.parse)
        );
      }

      setDefaultCenterMap({
        lat: response?.data?.centerPoint?.coordinates[1],
        lng: response?.data?.centerPoint?.coordinates[0],
      });

      if (page < response.data.totalPages) {
        await fetchPlottingPoints(id, page + 1);
      } else {
        setLoadingPlotting(false);
      }
    } catch (error) {
      setShowSnackbar(true);
      setSnackbarMessage("Gagal memuat data dari server.");
      setLoadingPlotting(false);
    }
  };

  const fetchLand = async (page, id) => {
    try {
      setLoadingLand(true);
      const encodedSearchInput = encodeURIComponent("ladang kopi");
      const response = await getLand(encodedSearchInput, page, id);

      if (response?.data?.totalItems === 0) {
        setShowSnackbar(true);
        setSnackbarMessage("Belum ada data lokasi yang dapat ditampilkan.");
      }

      const index = usersData.items.findIndex((item) => item.userId === id);

      if (index !== -1) {
        setUsersData((prevData) => {
          const updatedItems = [...prevData.items];
          updatedItems[index] = {
            ...updatedItems[index],
            plottingData: response.data,
            open: true,
          };
          return {
            ...prevData,
            items: updatedItems,
          };
        });
      }
    } catch (error) {
      setShowSnackbar(true);
      setSnackbarMessage("Gagal memuat data dari server.");
    } finally {
      setLoadingLand(false);
    }
  };

  const onChangeInput = (event) => {
    let input = event.target.value;
    setSearchInput(input);

    clearTimeout(typingTimeout);

    setTypingTimeout(
      setTimeout(() => {
        onSearchInput();
      }, 2000)
    );
  };

  const onToggleSidebar = () => {
    setShowSidebarByMobile((prevstate) => {
      if (prevstate === "show") {
        return "hide";
      } else {
        return "show";
      }
    });
  };

  const onEndScroll = (number) => {
    if (usersData?.items?.length < usersData?.items?.totalItems) {
      fetchUsers(number);
    }
  };

  if (!code && !id && !lat && !long) {
    window.location.href = "https://www.pasarmikro.id";
    return (
      <div className="home-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", height: "100vh", width: "100%" }}>
      <Snackbar
        message={snackbarMessage}
        isVisible={showSnackbar}
        onCloseSnackbar={setShowSnackbar}
      />
      {!lat && !long && !code ? (
        <Sidebar
          onChangeInput={(text) => onChangeInput(text)}
          onSearchInput={onSearchInput}
          data={usersData}
          onSelectedList={onSelectedList}
          onSelectedUser={(item) => onSelectedUser(item)}
          onEndScroll={(number) => onEndScroll(number)}
          loadingScroll={loadingLand || loadingPlotting}
          onShowButtonMenu={onToggleSidebar}
          showToggle={toggleSidebar}
          showSidebar={showSidebarByMobile}
          selectedPlotting={selectedPlotting}
        />
      ) : null}

      <div
        style={{
          width: "100%",
          transition: "width 0.1s ease",
        }}
      >
        {loadingPlotting || loadingLand ? <LoadingCenter /> : null}
        <div
          style={{
            height: "100%",
            width: "100%",
            opacity: loadingPlotting || loadingLand ? 0.5 : 1,
          }}
        >
          <Map
            data={plottingPoints}
            center={defaultCenterMap}
            loading={loadingPlotting}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
