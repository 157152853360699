import React, { useEffect, useState } from "react";
import { Home, Login } from "../src/Views";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    checkLoginStatus().then((loggedIn) => {
      setIsLoggedIn(loggedIn);
    });
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const checkLoginStatus = async () => {
    try {
      const token = await AsyncStorage.getItem("access_token");
      return !!token;
    } catch (error) {
      console.error("Error checking login status:", error);
      return false;
    }
  };

  // return <div>{isLoggedIn ? <Home /> : <Login onLogin={handleLogin} />}</div>
  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<Home />}/>
        <Route path="/" element={<Home />}/>
      </Routes>
    </Router>

  )
}
