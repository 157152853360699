import React, { useEffect } from "react";
import "./snackbar.css";

const Snackbar = ({ message, isVisible, onCloseSnackbar }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onCloseSnackbar(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [isVisible]);

  return (
    <div className={`snackbar ${isVisible ? "show" : ""}`}>
      <span>{message}</span>
    </div>
  );
};

export default Snackbar;
