import axios from "axios";
import { http } from "./http";

export const login = async (username, password) => {
  try {
    const response = await http.post("/login", { username, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUsers = async (search, page) => {
  try {
    const response = await http.get(
      `/api/v1/page/landplottingbyuser?search=${search}&page=${page}&size=10`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getLand = async (search, page, id) => {
  try {
    const response = await http.get(
      `/api/v1/page/landplotting?userId=${id}&search=${search}`
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getPlottingPoint = async (code, page) => {
  try {
    const response = await http.get(
      `/api/v1/page/plottingpoint?code=${code}&sortby=id&sortdir=asc&page=${page}&size=150`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
