import React from "react";
import "./cards.css";
import { meterToArea } from "../../Helpers";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import LandCard from "./LandCard";

export default function UserCard({
  item,
  index,
  onSelectedUser,
  onSelectedList,
  selectedPlotting,
}) {
  return (
    <>
      <div className="user-card" key={index}>
        <div className="row" onClick={() => onSelectedUser(item)}>
          <div>
            <p className="title">{item?.name}</p>
            <p className="long-desc">
              Perkiraan luas total: {meterToArea(item?.totalSize)}
            </p>
            <p className="long-desc">Jumlah Lokasi: {item?.total}</p>
          </div>
          <div>
            {item?.open ? (
              <FaChevronDown color="lightGrey" />
            ) : (
              <FaChevronRight color="lightGrey" />
            )}
          </div>
        </div>
        {item?.open && item?.plottingData?.items?.length > 0
          ? item?.plottingData?.items?.map((el, i) => {
              return (
                <LandCard
                  item={el}
                  key={i}
                  onSelectedList={onSelectedList}
                  selectedPlotting={selectedPlotting}
                />
              );
            })
          : null}
      </div>
    </>
  );
}
