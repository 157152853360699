export const filterLatLong = (data) => {
  return data
    .map((location) => {
      if (location.plottingPoint && location.plottingPoint.coordinates) {
        return {
          lat: location.plottingPoint.coordinates[1],
          lng: location.plottingPoint.coordinates[0],
        };
      } else {
        console.error("Invalid location data:", location);
        return null;
      }
    })
    .filter(Boolean);
};

export const meterToArea = (meter) => {
  if (meter >= 10000) {
    return (meter / 10000).toFixed(2) + " ha";
  } else {
    return (meter / 1000).toFixed(2) + " km²";
  }
};
