import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const authToken = process.env.REACT_APP_AUTH_TOKEN;

const axiosInstance = axios.create({
  baseURL,
  timeout: 10000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token");
    // if (token) {
    //   config.headers.Authorization = authToken;
    // }
    config.headers.Authorization = authToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

export const http = axiosInstance;
