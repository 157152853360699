import React, { useEffect, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import { UserCard } from "../Cards";
import "./sidebar.css";

const Sidebar = ({
  onChangeInput,
  onSearchInput,
  data,
  onSelectedList,
  onEndScroll,
  loadingScroll,
  onShowButtonMenu,
  showToggle,
  showSidebar,
  onSelectedUser,
  selectedPlotting,
}) => {
  const containerRef = useRef(null);
  const prevScrollTop = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollPercentage > 80 && scrollTop > prevScrollTop.current) {
        if (data?.currentPage < data?.totalPages && !loadingScroll) {
          onEndScroll(data?.currentPage + 1);
        }
      }

      prevScrollTop.current = scrollTop;
    };

    containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      containerRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [data?.items, data?.currentPage, data?.totalPages, loadingScroll]);
  return (
    <>
      <div
        className={`menu-button ${showSidebar === "hide" ? "go-bottom" : ""}`}
      >
        <div className={"header-wrapper"}>
          <div className="header">
            <FiMenu
              className={`sidebar-icon ${loadingScroll ? "loading" : ""}`}
              onClick={onShowButtonMenu}
            />
          </div>
        </div>
      </div>
      <div
        ref={containerRef}
        className={`sidebar-container ${showSidebar}`}
        style={{
          width: data?.items?.length > 0 ? "30%" : "0%",
          transition: "width 3s ease",
        }}
      >
        {/* // input search if needed
      <div className={"header-wrapper"}>
        <div className="header">
          <img
            src="https://www.pasarmikro.id/favicon.ico"
            alt="Menu"
            className="sidebar-icon"
          />
          <div className="search-container">
            <input
              type="text"
              placeholder="Cari..."
              name="q"
              className="search-input"
              onChange={(text) => onChangeInput(text)}
            />
          </div>
          <FiSearch
            className={`sidebar-icon ${loadingScroll ? "loading" : ""}`}
            onClick={onSearchInput}
          />
        </div>
      </div> */}
        <div className="search-results" ref={containerRef}>
          {data?.items?.map((el, i) => {
            return (
              <UserCard
                item={el}
                key={i}
                onSelectedUser={onSelectedUser}
                onSelectedList={onSelectedList}
                selectedPlotting={selectedPlotting}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
