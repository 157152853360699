import React from "react";
import "./cards.css";
import { meterToArea } from "../../Helpers";

export default function LandCard({
  item,
  index,
  onSelectedList,
  selectedPlotting,
}) {
  return (
    <div
      className={`land-card-item ${
        item?.id === selectedPlotting?.id ? "colored" : null
      }`}
      onClick={() => onSelectedList(item)}
      key={index}
    >
      <p className="desc black">{item?.landName}</p>
      <p className="long-desc">Perkiraan luas: {meterToArea(item?.size)}</p>
      <p className="desc">{item?.district}</p>
      <p className="long-desc">{item?.address}</p>
    </div>
  );
}
